import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Internship } from '../models/internship';
import { InternshipService } from '../services/internship.service';
import { GlobalSettings } from '../../shared/global-settings';

import { GridRequest } from '../models/grid-request';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { FilterMetadata } from 'primeng/api';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../layout/shared/error-dialog/error-dialog.component';
import { uuidv4 } from '../../layout/shared/shared.tools';

@Component({
  selector: 'app-internship-list',
  templateUrl: './internship-list.component.html',
  styleUrls: ['./internship-list.component.css'],
})
export class InternshipListComponent implements OnInit, AfterViewInit {
  public internships: Internship[];
  public isUpdating: boolean = false;
  public fatalError: boolean = false;
  public error: Error;
  public reportUrl: string =
    '/src/' + GlobalSettings.navigate.internshipDetailReport;
  public isIe: boolean = false;
  public totalRecords: number;
  public gridRequest: GridRequest;
  public pageSize: number;
  public withArchive: boolean = false;
  public deptString: string;
  @ViewChild('internshipDataTable') internshipDataTable: Table;

  /**
   * Constructor of the class.
   *
   * @param {ActivatedRoute} activatedRoute
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private internshipService: InternshipService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.gridRequest = new GridRequest();
  }

  /**
   * On component init we store resolved data, so that we can use those on GUI.
   */
  ngOnInit(): void {
    this.isUpdating = false;
    this.pageSize = GlobalSettings.pageSize;
    this.gridRequest = this.createEmptyGridRequest();
    this.loadLazyFromService();
    this.internshipService
      .getDeptString()
      .then((res) => {
        this.deptString = res;
      })
      .catch((error) => {
        this.setError(error);
      });
  }

  ngAfterViewInit(): void {
    this.internshipDataTable.filterDelay = 600;
  }

  customFilterChanged() {
    this.gridRequest.withArchive = this.withArchive;
    this.gridRequest.filterOrSortChanged = true;
    this.loadLazyFromService();
  }

  loadLazy(event: TableLazyLoadEvent) {
    let gridRequest: GridRequest = this.createEmptyGridRequest();
    gridRequest.offset = event.first;
    gridRequest.sortfield = event.sortField as string;
    gridRequest.sortAsc = event.sortOrder === 1;
    gridRequest.filters = event.filters;
    gridRequest.withArchive = this.withArchive;

    gridRequest.filterOrSortChanged = true;
    this.gridRequest = gridRequest;
    this.loadLazyFromService();
  }

  loadLazyFromService() {
    this.isUpdating = true;
    this.gridRequest.withArchive = this.withArchive;
    this.internshipService
      .getInternships(this.gridRequest)
      .then((res) => {
        this.isUpdating = false;
        this.totalRecords = res.count;
        this.internships = res.internships;
        this.setError(null);
      })
      .catch((error) => {
        this.isUpdating = false;
        this.setError(error);
      });
  }

  openPdfInNewTab(id: number) {
    const showDialogTime = 1300;
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        message: 'Pdf Download gestartet',
      },
    });
    dialogRef.afterOpened().subscribe(() => {
      setTimeout(() => {
        dialogRef.close();
      }, showDialogTime);
    });

    this.internshipService
      .downloadRegistrationReport(id)
      .subscribe((data: Blob) => {
        var blob = new Blob([data], { type: 'application/pdf' });
        const name = uuidv4();
        this.internshipService.downloadBlob(blob, name, '.pdf');
        const showDialogTime = 1300;

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Pdf Dowload fertig',
          },
        });
        dialogRef.afterOpened().subscribe(() => {
          setTimeout(() => {
            dialogRef.close();
          }, showDialogTime);
        });
      });
  }

  setError(error: Error | any) {
    this.error = error;
    if (error == null) {
      this.fatalError = false;
      return;
    }
    this.fatalError = error.status === 403;
  }

  createEmptyGridRequest(): GridRequest {
    let gridRequest = new GridRequest();
    gridRequest.pageSize = GlobalSettings.pageSize;
    gridRequest.withArchive = true;
    gridRequest.offset = 0;
    gridRequest.sortAsc = true;
    return gridRequest;
  }
}
