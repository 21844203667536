import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InternshipService } from '../services/internship.service';
import { WorkflowStepService } from '../services/workflow-step.service';
import { GlobalSettings } from '../../shared/global-settings';
import { InternshipDetail } from '../models/internship-detail';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../layout/shared/error-dialog/error-dialog.component';
import { uuidv4 } from '../../layout/shared/shared.tools';
import { UserGroup } from '../models/usergroup';
import { GradeService } from '../services/grade.service';
import { GradingScaleElement } from '../models/grading-scale-element';
import { Trainer } from '../models/trainer';
import { TrainerSelectDialogComponent } from '../trainer-select-dialog/trainer-select-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-internship-overview',
  templateUrl: './internship-overview.component.html',
  styleUrls: ['./internship-overview.component.css'],
})
export class InternshipOverviewComponent implements OnInit {
  item: InternshipDetail;
  error: Error;
  isUpdating: boolean = false;
  fatalError: boolean = false;
  public reportUrl: string =
    '/src/' + GlobalSettings.navigate.internshipDetailReport;
  isEntranceInterview: boolean;
  isStatusReview1: boolean;
  isStatusReview2: boolean;
  isNeuState: boolean;
  isQualifikationState: boolean;
  isFreigabeState: boolean;

  usergroups: UserGroup = null;
  isStudent = true;
  isStudienbegleitende = false;
  deptString: string;

  zmcNotenskala: GradingScaleElement[];
  zmcNotenskalaDataSourceObjectId = 'D4AA6092-B127-4549-BD7B-03F82297C10E';

  neuDataSourceObjectId = '2B076B6B-F73F-43CE-8B0A-C84AE25DAE59';
  qualifikationDataSourceObjectId = '0548FC6C-0C8B-499F-8098-0C3A894F4C44';
  abschliessenDataSourceObjectId = 'FB09030C-BE6F-4EE8-8F01-F0DD63D63639';
  freigabeDataSourceObjectId = 'DE408CBE-FA92-4878-86C9-96F1FAD95C61';

  constructor(
    private internshipService: InternshipService,
    private gradeService: GradeService,
    private workflowStepService: WorkflowStepService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.params['id'];
    if (id) {
      this.isUpdating = true;
      this.setError(null);
      Promise.all([
        this.gradeService
          .getGradingScaleElement(this.zmcNotenskalaDataSourceObjectId)
          .then((items) => {
            const temp: GradingScaleElement[] = items;
            this.zmcNotenskala = [];
            this.zmcNotenskala.push(
              temp.find(
                (item) =>
                  item.dataSourceObjectId ===
                  'E64C72C7-F2FB-4010-ACE4-99E5A7DC1F47'
              )
            );
            this.zmcNotenskala.push(
              temp.find(
                (item) =>
                  item.dataSourceObjectId ===
                  '7270D0DA-50CC-4647-9FF9-C0E6B57B7356'
              )
            );
            this.zmcNotenskala.push(
              temp.find(
                (item) =>
                  item.dataSourceObjectId ===
                  '15D05230-8205-4A6D-9092-EA3B107EE244'
              )
            );
            if (this.zmcNotenskala.includes(undefined)) {
              this.zmcNotenskala = items;
            }
          })
          .catch((error) => {
            this.setError(error);
            this.isUpdating = false;
          }),

        this.internshipService
          .getInternshipDetail(id)
          .then((item) => {
            this.item = item;
            this.initItem();
          })
          .catch((error) => {
            this.isUpdating = false;
            this.setError(error);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Das Arbeitsverhältnis konnte nicht geladen werden.',
              },
            });
          }),

        this.internshipService
          .UserHasStudentRole(id)
          .then((usergroups) => {
            this.usergroups = usergroups;
            this.isStudent =
              usergroups.dataSourceObjectId ===
              'BA3363BE-10CD-4CE2-B831-EC7E34D7BBA8';
            this.isStudienbegleitende =
              usergroups.dataSourceObjectId ===
              '34A08189-0042-40C1-8EC5-97DD68A10457';
          })
          .catch((error) => {
            this.setError(error);
            this.isUpdating = false;
          }),

        this.internshipService
          .getDeptString()
          .then((res) => {
            this.deptString = res;
          })
          .catch((error) => {
            this.setError(error);
            this.isUpdating = false;
          }),
      ]).then(() => {
        this.isUpdating = false;
      });
    }
  }

  initItem(): void {
    const state = this.item.workflowState.dataSourceObjectId;
    this.isNeuState = state === this.neuDataSourceObjectId;
    this.isQualifikationState = state === this.qualifikationDataSourceObjectId;
    this.isFreigabeState = state === this.freigabeDataSourceObjectId;

    this.isEntranceInterview =
      this.isNeuState &&
      (!this.item.entranceInterviewStudent ||
        !this.item.entranceInterviewTrainer);

    this.isStatusReview1 =
      this.isNeuState &&
      this.item.entranceInterviewStudent &&
      this.item.entranceInterviewTrainer &&
      (!this.item.statusReview1Student || !this.item.statusReview1Trainer);

    this.isStatusReview2 =
      this.isNeuState &&
      this.item.statusReview1Student &&
      this.item.statusReview1Trainer &&
      !(this.item.statusReview2Student && this.item.statusReview2Trainer) &&
      !this.item.noStatusReview2;

    if (this.item.entranceInterview === null) {
      this.item.entranceInterview =
        '<p><strong>Datum des Eintrittsgesprächs: </strong></p><p><br></p>' +
        '<p><strong>Festlegung des Ausbildungsstandes:</strong></p><p><br></p><p>&nbsp;</p>' +
        '<p><strong>Entwicklungs- und Förderungsmassnahmen aus vorangegangenen Praxismodulen:</strong></p><p><br></p><p>&nbsp;</p>';
    }
    if (this.item.statusReview1 === null) {
      this.item.statusReview1 =
        '<p><strong>Datum des Standortgespräch 1: </strong></p><p><br></p>' +
        '<p><strong>Stellungnahme zu den sieben Rollen und deren Kompetenzen:</strong></p><p><br></p><p>&nbsp;</p>' +
        '<p><strong>Entwicklungs- und Förderungsmassnahmen:</strong></p><p><br></p><p>&nbsp;</p>' +
        '<p><strong>Bemerkungen der/des Praxisausbildenden:</strong></p><p><br></p><p>&nbsp;</p>';
    }
    if (this.item.statusReview2 === null) {
      this.item.statusReview2 =
        '<p><strong>Datum des Standortgespräch 2: </strong></p><p><br></p>' +
        '<p><strong>Stellungnahme zu den sieben Rollen und deren Kompetenzen:</strong></p><p><br></p><p>&nbsp;</p>' +
        '<p><strong>Entwicklungs- und Förderungsmassnahmen:</strong></p><p><br></p><p>&nbsp;</p>' +
        '<p><strong>Bemerkungen der/des Praxisausbildenden:</strong></p><p><br></p><p>&nbsp;</p>';
    }
  }

  executeWorkflowStep(
    registrationId: number,
    workflowStepId: number,
    reverse: boolean,
    workflowStepDataSourceObjectId: string
  ) {
    if (
      this.deptString === 'IHB' &&
      workflowStepDataSourceObjectId === this.abschliessenDataSourceObjectId &&
      !this.requiredFieldsSetForAbschliessen()
    ) {
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Alle Pflichtfelder müssen ausgefüllt sein.',
        },
      });
      dialogRef.afterOpened().subscribe(() => {
        setTimeout(() => {
          dialogRef.close();
        }, 1300);
      });
      return;
    }
    this.isUpdating = true;
    this.setError(null);
    this.workflowStepService
      .executeWorkflowStep(registrationId, workflowStepId, reverse)
      .then(
        (item) => {
          this.item = item;
          this.initItem();
          this.internshipService.resetCache();
          this.isUpdating = false;
        },
        (error) => {
          this.isUpdating = false;
          this.setError(error);
        }
      );
  }

  openPdfInNewTab(id: number) {
    const showDialogTime = 1300;
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        message: 'Pdf Download gestartet',
      },
    });
    dialogRef.afterOpened().subscribe(() => {
      setTimeout(() => {
        dialogRef.close();
      }, showDialogTime);
    });
    this.internshipService
      .downloadRegistrationReport(id)
      .subscribe((data: Blob) => {
        var blob = new Blob([data], { type: 'application/pdf' });
        const name = uuidv4();
        this.internshipService.downloadBlob(blob, name, '.pdf');
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Pdf Dowload fertig',
          },
        });
        dialogRef.afterOpened().subscribe(() => {
          setTimeout(() => {
            dialogRef.close();
          }, showDialogTime);
        });
      });
  }

  setError(error: Error | any) {
    this.error = error;
    if (error == null) {
      this.fatalError = false;
      return;
    }
    this.fatalError = error.status === 403;
  }

  save(): void {
    this.isUpdating = true;
    this.internshipService
      .updateInternshipDetail(this.item)
      .then((item) => {
        this.isUpdating = false;
        this.initItem();
      })
      .catch((error) => {
        this.isUpdating = false;
        this.setError(error);
      });
  }

  reopenEntranceInterview(event: any): void {
    event.stopPropagation();
    this.item.entranceInterviewStudent = false;
    this.item.entranceInterviewTrainer = false;
    this.initItem();
  }

  reopenStatusReview1(event: any): void {
    event.stopPropagation();
    this.item.statusReview1Student = false;
    this.item.statusReview1Trainer = false;
    this.initItem();
  }

  reopenStatusReview2(event: any): void {
    event.stopPropagation();
    this.item.statusReview2Student = false;
    this.item.statusReview2Trainer = false;
    this.item.noStatusReview2 = false;
    this.initItem();
  }

  requiredFieldsSetForAbschliessen(): boolean {
    return (
      this.item.absences !== null &&
      this.item.absences != '' &&
      this.item.overallAssessment !== null &&
      (!this.item.showOverallGrade123 ||
        (this.item.grade !== null &&
          this.item.grade != '' &&
          this.item.grade != '0')) &&
      (!this.item.showOverallGrade456 || this.item.zmcGradeId !== null) &&
      this.item.developmentMeasures !== null &&
      this.item.confirmationStudent &&
      this.item.confirmationTrainer
    );
  }

  openTrainers(): void {
    const dialogRef = this.dialog.open(TrainerSelectDialogComponent, {
      height: '75%',
    });
    dialogRef.componentInstance.organization =
      this.item.practiceOrganization.name;

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      if (result !== undefined) {
        var trainer = result as Trainer;
        this.item.trainerId = trainer.id;
        this.save();
      }
    });
  }

  confirmationStudentChanged(event: MatCheckboxChange): void {
    console.log(event.checked);
    if (event.checked) {
      const today = new Date();
      this.item.assessmentFormReceiptIs = today.toISOString().slice(0, 10);
    } else {
      this.item.assessmentFormReceiptIs = null;
    }
  }
}
